<template>
    <div class="MemberOrderCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="门店">
                            <el-input readonly v-model="form.deptName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input v-model="orderCreate.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="orderCreate.creator" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-date-picker v-model="orderCreate.createDate" value-format="yyyy-MM-dd" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="queryAndAdd" size="small">选择商品</el-button>
            <el-button type="primary" @click="handleSelectGoods" size="small">选择商品基本信息</el-button>
            <el-button type="primary" @click="handleSave" size="small" :disabled="tableData.length == 0 ? true : false"
                >保存</el-button
            >
        </el-card>
        <pick-up-goods ref="pickUpGoods" @queryGoods="handleSelectGoodsOk" />
        <el-card shadow="never" style="margin-top: 8px" align="left">
            <el-table
                id="pickUpMembersTable"
                border
                ref="pickUpMembersTable"
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="480"
            >
                <el-table-column width="60" type="selection" />
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column prop="orderName" label="订单名称" width="180">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.orderName" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column prop="sku.name" label="商品" width="120" />
                <el-table-column prop="type" label="类型" width="120">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.sku.type | type }}</span></template
                    >
                </el-table-column>
                <el-table-column prop="sku.bars" label="商品条码" width="120" />
                <el-table-column prop="price" label="订购价" width="120">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.price" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column prop="reserveCount" label="数量" width="120">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.reserveCount" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="80" header-align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="deleteRow(scope.$index, tableData, scope.row)"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import Util from 'js/Util';
import PickUpGoods from 'components/PickUpGoods';

export default {
    name: 'MemberOrderCreate',
    components: { PickUpGoods },
    props: ['form'],
    data() {
        return {
            orderCreate: {
                createDate: new Date(),
                creator: this.$store.state.session.name,
                deptCode: this.form.deptCode,
                search: '',
                skuType: 0,
            },
            tableData: [],
            url: {
                save: '/reserve/order/createExtend',
                queryGoods: '/goods/goods/pageExtend',
            },
            queryData: {
                page: 1,
                search: '',
                deptCode: this.form.deptCode,
                limit: Util.Limit,
            },
        };
    },
    mounted() {},
    filters: {
        regChannel(regChannel) {
            if (typeof regChannel == 'undefined') {
                return '';
            }
            if (regChannel == '8') {
                return '后台新建';
            }
            if (regChannel == '10') {
                return '会员初始化';
            }
        },
        sex(sex) {
            if (typeof sex == 'undefined') {
                return '';
            }
            if (sex == '0') {
                return '男';
            } else if (sex == '1') {
                return '女';
            }
        },
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type == '0') {
                return '标品';
            } else if (type == '1') {
                return '非标品';
            }
        },
    },
    methods: {
        handleSelectGoods() {
            this.$refs.pickUpGoods.show(this.pickUpGoodsQueryParam(), this.form.deptName);
        },
        queryAndAdd() {
            const _this = this;
            if (!this.orderCreate.search.trim()) {
                return;
            }
            const params = { params: { ...this.pickUpGoodsQueryParam(), search: this.orderCreate.search.trim() } };
            Util.queryTable(this, this.url.queryGoods, params, (data) => {
                if (data.status === 200) {
                    let _a = this.tableData;
                    data.data.forEach((v) => {
                        _a.push(v);
                    });
                    _a = _this.duplicate(_a, 'skuCode');
                    _this.tableData = _a;
                }
            });
        },
        pickUpGoodsQueryParam() {
            return { deptCode: this.form.deptCode, goodsStatusIn: [1], skuType: 0 };
        },
        handleSelectGoodsOk(data) {
            const _this = this;
            let _a = this.tableData;
            data.forEach((v) => {
                _a.push(v);
            });
            _a = _this.duplicate(_a, 'skuCode');
            _this.tableData = _a;
        },
        deleteRow(index, rows, row) {
            rows.splice(index, 1);
        },

        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page2, _params);
        },
        handleSave() {
            const _params = {
                goodsCode: this.tableData.map((d) => d.code),
                price: this.tableData.map((d) => d.price),
                reserveCount: this.tableData.map((d) => d.reserveCount),
                orderName: this.tableData.map((d) => d.orderName),
                bizCode: this.form.bizCode,
                deptCode: this.form.deptCode,
            };
            UrlUtils.PostRemote(this, this.url.save, _params);
        },
        handleDelete(index) {
            const _a = this.tableData;
            _a.splice(index, 1);
            this.tableData = _a;
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
